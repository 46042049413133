import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../components/Layouts/Layouts";
import Seo from "../components/Layouts/Seo";
import BannerBasic from "../components/Content/Banner/Basic";
import LeadIn from "../components/Content/LeadIn/Index";
import CardArticle from "../components/Content/CardArticle/Index";

interface Props {
  readonly data: PageQueryData;
}

const CaseStudiesPage: React.FC<Props> = ({ data }) => {
  const caseStudies = data.allMarkdownRemark.edges;

  return (
    <Layout>
      <Seo
        title="Case Studies"
        description="See how Conex Bänninger products are being used in Australia and across the world through various projects."
      />
      <BannerBasic title="Case Studies" />
      <LeadIn
        paddingSize="medium"
        summary="See how Conex Bänninger products are being used in Australia and across the world through various projects."
      />

      <section className="uk-section uk-section-large uk-padding-remove-top">
        <div className="uk-container">
          <ul
            className="uk-child-width-1-3@s uk-grid-medium uk-grid-match"
            data-uk-grid="margin: uk-margin-large-top"
          >
            {caseStudies.map(({ node }) => {
              const { frontmatter } = node;
              const { category, slug, title } = frontmatter;
              const image: ImageData | undefined = frontmatter.images.length
                ? frontmatter.images[0]
                : undefined;

              return (
                <li key={slug}>
                  <CardArticle
                    mediaAlt={image?.altText}
                    mediaSrc={image?.path}
                    title={title}
                    subtitle={category}
                    url={slug}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </section>
    </Layout>
  );
};

interface ImageData {
  altText: string;
  caption: string;
  path: string;
}

interface PageQueryData {
  site: {
    siteMetadata: {
      title: string;
    };
  };
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string;
        frontmatter: {
          date: string;
          category: string;
          slug: string;
          title: string;
          images: ImageData[] | [];
        };
      };
    }[];
  };
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { published: { ne: false } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            category
            date(formatString: "MMMM DD, YYYY")
            images {
              altText
              path
            }
            slug
            title
          }
        }
      }
    }
  }
`;

export default CaseStudiesPage;
